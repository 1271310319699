import React from 'react'

function Footer() {
    return (
          
         <footer  className='footer bg-dark mt-2 text-white text-center'>
             <h1>Nasa Application</h1>
         </footer>
    )
}

export default Footer
